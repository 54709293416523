import React from "react";
import styles from "./HotelContent.scss";
import HotelDescription from "../../../components/HotelDescription/HotelDescription";
import HotelBookingSearchForm from "../../../components/BookingSearchForm/HotelBookingSearchForm";
import RoomAvailabilityCalendarWithFilter from "../../../components/RoomAvailabilityCalendarWithFilter/RoomAvailabilityCalendarWithFilter";
import {useSelector} from "react-redux";
import {IAppState} from "../../../store/AppState";
import {CalendarMode} from "../../../data/Calendar/Calendar";
import HotelImage4 from "../../..//images/HotelImage4.png";
import HourlyRental from "../../../images/HourlyRental.png";
import WidgetStore from "../../../widget/WidgetStore";
import {Gapped} from "@skbkontur/react-ui";
import HotelServices from "./HotelServices";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";

const HotelContent = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const displayAvailabilityCalendar = useSelector(
        (state: IAppState) => state.hotelInfo.info.displayAvailabilityCalendar
    );
    const {hourlyObjects} = useSelector((state: IAppState) => state.hourlyObjects);
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);

    return (
        <section className={styles.infoWrapper}>
            <div className={styles.mainColumn}>
                <div className={styles.inner}>
                    <HotelDescription/>
                </div>
                <Gapped className={styles.hotelServices} vertical gap={30}>
                    <span className={styles.title}>{t("hotelServices")}</span>
                    <div className={styles.box}>
                        {!!roomCategories?.length && (
                            <HotelServices
                                href="#rooms"
                                text={t("roomReservation", {stringFormat: true})}
                                imageSrc={WidgetStore.getUrlWithBase(HotelImage4)}
                            />
                        )}
                        {!!hourlyObjects.length && (
                            <HotelServices
                                href="#services"
                                text={t("hourlyRental", {stringFormat: true})}
                                imageSrc={WidgetStore.getUrlWithBase(HourlyRental)}
                            />
                        )}
                    </div>
                </Gapped>
            </div>
            <div className={styles.sideColumn}>
                <div className={styles.inner}>
                    <HotelBookingSearchForm onFrontPage showFixedButtonOnMobile/>
                    {displayAvailabilityCalendar && (
                        <div className={styles.calendarWidget}>
                            <RoomAvailabilityCalendarWithFilter
                                calendarMode={CalendarMode.OneMonthOnHotelPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
HotelContent.displayName = "HotelContent";
export default HotelContent;
