import styles from "./PaymentByLinkPage.scss";
import React from "react";
import {RouteComponentProps, useHistory} from "react-router";
import {useSearchParams} from "../../common/hooks/useSearchParams";
import PaymentByLinkPageByStatus from "./PageByStatus/PaymentByLinkPageByStatus";
import {useMount} from "@skbkontur/hotel-hooks/react";

interface IPaymentByLinkPageProps {
    id: string;
}

const PaymentByLinkPage = (props: RouteComponentProps<IPaymentByLinkPageProps>) => {
    const [isFromHotel, setIsFromHotel] = React.useState<boolean>(false);

    const history = useHistory();
    const paymentId = props.match.params.id;
    const fromHotel = React.useRef(useSearchParams().fromHotel);

    useMount(() => {
        if (fromHotel.current) {
            history.replace(paymentId);
            setIsFromHotel(true);
        }
    });

    return (
        <div className={styles.container}>
            {isFromHotel
                ? <PaymentByLinkPageByStatus paymentId={paymentId}/>
                : null}
        </div>
    );
};

export default PaymentByLinkPage;
PaymentByLinkPage.displayName = "PaymentByLinkPage";
